body {
	padding: 0;
	margin: 0;
	background: #000;
}

.App {
	padding-top: env(safe-area-inset-top);
}

body * {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

div,
h1,
h2,
h3,
h4,
h5,
h6,
span,
p,
a,
label,
input {
	font-family: 'Open Sans', sans-serif !important;
}
.sidebarLink .drawerItem {
	border-radius: 6px;
	border-left: 5px solid transparent;
}
.sidebarLink.activeItem > .drawerItem {
	background: #eaeaea !important;
	border-left-color: inherit;
}

.icon span {
	display: flex;
	margin: 0px;
}

.menu > .divider {
	margin: 0 !important;
}

.ui.dropdown .add_dropdown {
	width: 300px;
}

.ui.menu .ui.dropdown .add_dropdown > .item {
	font-size: 14px !important;
	padding: 20px 32px !important;
	color: #252733 !important;
}

.ui.disabled.dropdown,
.ui.dropdown .add_dropdown > .disabled.item.item {
	font-size: 18px !important;
	font-weight: 600 !important;
	opacity: 1;
}

.ui.disabled.dropdown,
.ui.dropdown .add_dropdown > .disabled.item.item {
	font-size: 18px !important;
	font-weight: 600 !important;
	opacity: 1;
}
.dropdown {
	display: flex !important;
	align-items: center !important;
}
.ui.modals {
	z-index: 99999;
}
.field {
	margin-bottom: 8px !important;
}
.ui.grid > .column:not(.row) {
	padding-top: 0rem !important;
	padding-bottom: 12px !important;
}
.field {
	border-radius: 4px !important;
}

.disabled .field.field {
	background: #f2f2f2 !important;
	border: 1px solid #e0e0e0 !important;
	opacity: 1 !important;
}

.ui .label {
	display: flex;
	align-items: center;
}

.MuiDialog-root {
	z-index: 130000000 !important;
	padding-top: env(safe-area-inset-top);
}

.coursewizard-input-wrapper {
	display: flex;
	align-items: center;
	margin-bottom: 40px;
}

.coursewizard-input-wrapper--disabled * {
	opacity: 0.5;
	cursor: not-allowed;
}

.coursewizard-input-wrapper.wysiwyg,
.coursewizard-input-wrapper.exercise-wrapper {
	align-items: flex-start;
}

.coursewizard-input-wrapper.exercise-wrapper label {
	margin-top: 5px;
}

.coursewizard-input-wrapper .coursewizard-label {
	width: 195px;
	min-width: 195px;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	flex-direction: column;
	font-size: 16px;
	padding-right: 12px;
	margin-right: 12px;
	line-height: 32px;
	border-right: 1px solid #dfe0eb;
}

.coursewizard-input-wrapper .coursewizard-label .coursewizard-label-image-top{
	margin-bottom: -5px;
}

.coursewizard-input-wrapper .coursewizard-label .coursewizard-label-image-sub{
	font-size: 12px;
	color: #909090;
	text-align: right;
	line-height: 18px;
}

.coursewizard-input-wrapper .coursewizard-label.wysiwyg-open {
	margin-top: 97px;
}

.coursewizard-input-wrapper .coursewizard-placeholder {
	color: #9c9c9c;
	cursor: text;
	font-size: 16px;
	margin-top: 5px;
}

.coursewizard-input-wrapper input,
.coursewizard-input-wrapper textarea {
	appearance: none;
	-webkit-appearance: none;
	background-color: transparent;
	border: none;
	resize: none;
	flex-grow: 1;
	height: auto;
}

.coursewizard-input-wrapper input:focus,
.coursewizard-input-wrapper textarea:focus {
	outline: none;
}

.coursewizard-input-wrapper textarea {
	padding-top: 20px;
}

.rdw-editor-wrapper {
	width: 800px;
}

.dropdown > .text {
	margin-left: 15px !important;
}

.rdw-image-modal-url-input {
	width: 90% !important;
	height: 35px !important;
	margin: 15px 0 12px !important;
	border: 1px solid #f1f1f1 !important;
	border-radius: 2px !important;
	font-size: 15px !important;
	padding: 0 5px !important;
}
.ck-editor {
	width: 100% !important;
	margin-top: 50px !important;
}
.ck.ck-editor .ck.ck-content {
	min-height: 300px !important;
	max-height: 50vh !important;
}
.fill-the-gaps .ck-editor {
	width: 100% !important;
	margin-top: 0 !important;
}

.ck.ck-reset_all svg {
	width: 16px;
	height: 18px;
}

.authoring_navigation_select {
	border: 1px solid white !important;
	background: #252733 !important;
	width: 180px !important;
}
.authoring_navigation_select > i {
	color: white !important;
}

.authoring_navigation_select > .text {
	color: white !important;
}

.disabled_dnd .dnd_item {
	color: lightgray !important;
}

.app-input label {
	font-size: 18px;
}
.app-input .MuiInput-formControl {
	height: 40px;
}

.apexcharts-legend {
	top: 0px !important;
}

.MuiCircularProgress-root svg {
	margin-right: 0 !important;
}

@media screen and (min-width: 1070px) {
	#freshdesk,
	#close_freshdesk {
		display: inline-flex;
	}
}
