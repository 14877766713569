.rowWrapper {
	height: 100%;
	box-sizing: border-box;
	cursor: move;
}
.rowWrapper:hover {
	opacity: 0.7;
}
.rowWrapper:active {
	opacity: 1;
}

.rowWrapperDragDisabled {
	cursor: default;
}

.row {
	height: 100%;
	white-space: nowrap;
	display: flex;
	position: relative;
}
.row > * {
	box-sizing: border-box;
}

/**
 * The outline of where the element will go if dropped, displayed while dragging
 */
.rowLandingPad,
.rowCancelPad {
	border: none;
	box-shadow: none;
	outline: none;
}
.rowLandingPad *,
.rowCancelPad * {
	opacity: 0 !important;
}
.rowLandingPad::before,
.rowCancelPad::before {
	background-color: lightblue;
	border: 2px dotted black;
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: -1;
}

.rowHidden,
.childHidden {
	opacity: 0.5;
}

.rowVisible ~ .isChild {
	opacity: 1;
}

/**
 * Alternate appearance of the landing pad when the dragged location is invalid
 */
.rowCancelPad::before {
	background-color: #e6a8ad;
}

/**
 * Nodes matching the search conditions are highlighted
 */
.rowSearchMatch {
	box-shadow: inset 0 -7px 7px -3px #0080ff;
}

/**
 * The node that matches the search conditions and is currently focused
 */
.rowSearchFocus {
	box-shadow: inset 0 -7px 7px -3px #fc6421;
}

.toolbarButton,
.rowToolbar,
.rowLabel,
.rowContents {
	display: inline-block;
	vertical-align: middle;
}

.rowContents {
	position: relative;
	height: 100%;
	flex: 1 0 auto;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.rowLabel {
	flex: 0 1 auto;
	padding-right: 20px;
}

.rowToolbar {
	flex: 0 1 auto;
	display: flex;
}

.collapseButton,
.expandButton {
	appearance: none;
	border: none;
	background: transparent;
	padding: 0;
	z-index: 2;
	position: absolute;
	top: 45%;
	width: 30px;
	height: 30px;
	transform: translate3d(-50%, -50%, 0);
	cursor: pointer;
}
.collapseButton::after,
.expandButton::after {
	content: '';
	position: absolute;
	transform-origin: 7px 4px;
	transform: translate3d(-50%, -20%, 0);
	border: solid transparent 10px;
	border-left-width: 7px;
	border-right-width: 7px;
	border-top-color: gray;
}
.collapseButton:hover::after,
.expandButton:hover::after {
	border-top-color: black;
}
.collapseButton:focus,
.expandButton:focus {
	outline: none;
}
.collapseButton:focus::after,
.expandButton:focus::after {
	filter: drop-shadow(0 0 1px #83bef9) drop-shadow(0 0 1px #83bef9)
		drop-shadow(0 0 1px #83bef9);
}

.expandButton::after {
	transform: translate3d(-50%, -20%, 0) rotateZ(-90deg);
}

/**
 * Line for under a node with children
 */
.lineChildren {
	height: 100%;
	display: inline-block;
}

/* ==========================================================================
   Scaffold

    Line-overlaid blocks used for showing the tree structure
   ========================================================================== */
.lineBlock,
.absoluteLineBlock {
	height: 100%;
	position: relative;
	display: inline-block;
	flex: 0 0 auto;
}

.absoluteLineBlock {
	position: absolute;
	top: 0;
}

/*# sourceMappingURL=node-content-renderer.css.map */
